


























import { MedicaoReceptor } from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { MedicaoReceptorService } from '@/core/services/residuo';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class RelatorioMedicaoReceptor extends PageBase {
    
    service = new MedicaoReceptorService();
    item: MedicaoReceptor = new MedicaoReceptor();
    overlay: boolean = false;

    mounted() {
        const queryfilter = this.$route.query;
        const filter = this.service.MontaFiltro(queryfilter);
        this.overlay = true;
        
        this.service.ListarTudoFiltro(filter,'',"ContratoReceptor.Receptor" )
        .then(
            res=>{
                this.item = res.data.items;
            },
            err=>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() =>{
            this.overlay = false;
        })
    }


}

